@import "../../../style.scss";

.navbar {
    @include themify($themes){
    display: flex;
    align-items: center;
    justify-content: space-between;
   padding-top: 10px;
   padding-bottom: 10px;
    height: 50px;
    border-bottom: 1px solid themed("border");
    position: fixed;
    width: 100%;
    top: 0px;
    background-color: themed("bg");
    z-index: 5;
    min-width: 372px;
   
    .left{
        display: flex;
        align-items: center;
        gap: 20px;
        padding-left: 20px;
        .search{
            display: flex;
            align-items: center;
            gap: 10px;
            border: 1px solid themed("border");
            border-radius: 30px;
            padding: 10px;
            input{
                border: none;
                width: 300px;
                background-color: transparent;
                color: themed("textColor");

                @media screen and (max-width: 648px) {
       
            display: none;
        
      }
            }
        }
    }

  

    .right{
        display: flex;
        align-items: center;
        gap: 20px;
        padding-right: 20px;

        .user{
            display: flex;
            align-items: center;
            gap: 20px;
            font-weight: bold;
            span{
                color: themed("textColor");
                cursor: pointer;
                margin-right: 20px;
            }
            img{
                width: 30px;
                height: 30px;
                border-radius: 50%;
                object-fit: cover;
            }
        }
    }


    .Nav-Icon{
        color: themed("textColor");
        cursor: pointer;
    }

    @media screen and (max-width: 720px) {
        .search {
            width: 26px;
            margin-right: 10px;
            overflow: hidden; /* Ensures the text doesn't overflow when the width is reduced */
          //  transition: width 0.1s ease; 
        }

        .search:hover {
            width: 255px;
        }

        .search:hover input {
            display: block;
            z-index: 2;
        }

        .show {
            opacity: 1;
            transition: opacity 0.6s ease,
        }

        .Notshow {
            opacity: 0;
            transition: opacity 0.0s ease,
        }
        
      }

      @media screen and (max-width: 888px) {
        .userName {
            display: none !important;
        }
      }

      @media screen and (max-width: 639px) {
        .search:hover {
            width: 405px;
        }
      }

      @media screen and (max-width: 577px) {
        .search:hover {
            width: 340px;
        }
      }

      

      @media screen and (max-width: 555px) {
        .notification{
            display: none !important;
        }

        .search:hover {
            width: 300px;
        }
      }

      @media screen and (max-width: 543px) {
       

        .search:hover {
            width: 310px;
        }
      }

      @media screen and (max-width: 502px) {
       

        .search:hover {
            width: 255px;
        }
      }

     
}
}

.notification-wrapper {
  position: relative;

  .notification {
    position: relative;
    cursor: pointer;

    .notification-badge {
      position: absolute;
      top: -5px;
      right: -5px;
      width: 8px;
      height: 8px;
      background-color: #ff4444;
      border-radius: 50%;
    }
  }

  .notification-dropdown {
    position: absolute;
    top: 100%;
    right: -10px;
    width: 320px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    margin-top: 10px;
    z-index: 1000;

    &:before {
      content: '';
      position: absolute;
      top: -6px;
      right: 20px;
      width: 12px;
      height: 12px;
      background: white;
      transform: rotate(45deg);
      border-left: 1px solid rgba(0,0,0,0.1);
      border-top: 1px solid rgba(0,0,0,0.1);
    }

    .notification-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 20px;
      border-bottom: 1px solid #eee;

      h3 {
        margin: 0;
        font-size: 16px;
        font-weight: 600;
      }

      span {
        cursor: pointer;
        color: #666;
        
        &:hover {
          color: #333;
        }
      }
    }

    .notification-list {
      max-height: 360px;
      overflow-y: auto;

      .notification-item {
        display: flex;
        align-items: center;
        padding: 15px 20px;
        border-bottom: 1px solid #eee;
        cursor: pointer;
        transition: background-color 0.2s;

        &:hover {
          background-color: #f8f9fa;
        }

        &.unread {
          background-color: #f0f7ff;

          &:hover {
            background-color: #e6f0fa;
          }
        }

        .notification-icon {
          margin-right: 15px;
          color: #666;
          font-size: 20px;
        }

        .notification-content {
          flex: 1;

          p {
            margin: 0 0 5px 0;
            font-size: 14px;
            color: #333;
          }

          .notification-time {
            font-size: 12px;
            color: #888;
          }
        }
      }
    }

    .notification-footer {
      padding: 15px 20px;
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #eee;

      button {
        background: none;
        border: none;
        color: #007bff;
        font-size: 13px;
        cursor: pointer;
        padding: 0;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

// Add this to handle clicks outside the dropdown
@media (max-width: 768px) {
  .notification-dropdown {
    position: fixed;
    top: 60px; // Adjust based on your navbar height
    left: 0;
    right: 0;
    width: 100%;
    margin: 0;
    border-radius: 0;
    
    &:before {
      display: none;
    }
  }
}

.menu-wrapper {
  position: relative;

  .departments-dropdown {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background: rgba(255, 255, 255, 0.98);
    z-index: 1000;
    overflow-y: auto;
    animation: slideIn 0.3s ease-out;

    .departments-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 40px;
      border-bottom: 1px solid #eee;
      position: sticky;
      top: 0;
      background: white;
      z-index: 2;

      h2 {
        margin: 0;
        font-size: 24px;
        color: #333;
      }

      .close-icon {
        cursor: pointer;
        padding: 8px;
        border-radius: 50%;
        transition: background-color 0.2s;

        &:hover {
          background-color: #f0f0f0;
        }
      }
    }

    .departments-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 30px;
      padding: 40px;
      max-width: 1400px;
      margin: 0 auto;

      .department-category {
        h3 {
          color: #666;
          font-size: 18px;
          margin: 0 0 20px 0;
          padding-bottom: 10px;
          border-bottom: 2px solid #f0f0f0;
        }

        .department-list {
          display: flex;
          flex-direction: column;
          gap: 15px;

          .department-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px;
            border-radius: 10px;
            background: white;
            border: 1px solid #eee;
            cursor: pointer;
            transition: all 0.2s;

            &:hover {
              transform: translateY(-2px);
              box-shadow: 0 2px 5px rgba(196, 196, 196, 0.1);

              .arrow {
                transform: translateX(5px);
                opacity: 1;
              }
            }

            .department-info {
              display: flex;
              align-items: center;
              gap: 15px;

              span {
                color: #007bff;
                font-size: 24px;
              }

              h4 {
                margin: 0 0 5px 0;
                font-size: 16px;
                color: #333;
              }

              .employee-count {
                font-size: 13px;
                color: #666;
              }
            }

            .arrow {
              color: #007bff;
              opacity: 0;
              transition: all 0.2s;
            }
          }
        }
      }
    }
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

// Responsive styles
@media (max-width: 768px) {
  .departments-dropdown {
    .departments-header {
      padding: 15px 20px;
    }

    .departments-grid {
      padding: 20px;
      grid-template-columns: 1fr;
      gap: 20px;
    }
  }
}

