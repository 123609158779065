@import "../../../style.scss";

.message {
  @include themify($themes) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 107px;
    position: fixed;
    width: 78%;

    .contain {
      border: 1px solid themed("border");
      border-radius: 10px;
      background-color: themed("bg");
      width: 100%;
      height: 80vh;

      .bord {
        border-right: 1px solid themed("border");
        height: 80vh;
        padding-right: 0px;
      }

      .msg {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: themed("textColor");
        padding: 20px 20px 0px 20px;

        button {
          width: 70% !important;
        }
      }
      .user {
        display: flex;
        align-items: center;
        margin-top: 20px;
        padding: 0px 20px 0px 20px;
        cursor: pointer;

        .avatar {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: cover;
          margin-right: 20px;
        }

        .details {
          display: flex;
          flex-direction: column;
          color: themed("textColor");
          width: 100%;

          .preview {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }

        .userInfo {
          display: flex;
          gap: 20px;
        }
      }

      .profile {
        width: 100%;
        justify-content: center;
        display: flex;
        color: themed("textColor");
        padding-top: 20px;

        .profileGrid {
          justify-items: center;
          display: grid;

          .avatar {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            object-fit: cover;
          }

          .name {
            margin-bottom: 5px;
          }

          .icons {
            margin-top: 10px;
          }

          .icon {
            font-size: 30px;
            cursor: pointer;
          }
        }
      }

      .date {
        font-size: 11px;
        color: themed("textColor");
      }

      .hrColor {
        border-bottom: 1px solid themed("border");
        border-top: none;
        border-left: none;
        border-right: none;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .userBlock {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
      .userChat {
        color: #fff;
        background-color: #ac8cbe;
        padding: 10px 20px;
        border-radius: 50px 50px 0px 50px;
        width: 70%;
        align-self: flex-end;
        margin-top: 10px;
      }

      .NotUserChat {
        color: themed("textColor");
        border: 1px solid themed("border");
        padding: 10px 20px;
        border-radius: 50px 50px 50px 0px;
        width: 70%;
        margin-top: 10px;
      }

      .chatSection {
        display: flex;
        flex-direction: column;
        padding: 0px 20px;
      }

      .chat {
        margin-top: -74px;
        width: 95%;
        color: themed("textColor");
        display: flex;
        justify-content: space-between;
        background-color: themed("bg");
        padding-top: 10px;
        margin-left: 20px;
        margin-right: 20px;

        input {
          width: 80%;
          padding: 10px;
          border: 1px solid themed("border");
          background-color: transparent;
          color: themed("textColor");
          border-radius: 50px;

          margin-bottom: 10px;
        }
      }
    }

    @media screen and (max-width: 1166px) {
      .contain {
        // width: 80%;
        margin-left: -10px;
      }
    }

    @media screen and (max-width: 740px) {
      .contain {
        width: 123%;
        margin-left: -174px;
        min-width: 280px;
      }
    }

    @media screen and (max-width: 600px) {
      .reactionName {
        display: none;
      }
    }
  }
}
