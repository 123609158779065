@import "../../../style.scss";

.posts{
    @include themify($themes){
        display: flex;
        flex-direction: column;
        justify-content: center;
    .contain{
        padding: 20px;
        border:  1px solid themed("border");
        border-radius: 10px;
        background-color: themed("bg");
        width: 70%;
        margin: auto;
        .user{
            display: flex;
            align-items: center;
            justify-content: space-between;

          
            .userInfo{
                display: flex;
                gap: 20px;


                img{
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    object-fit: cover;
                }
                .details{
                    display: flex;
                    flex-direction: column;
                    color: themed("textColor");

                    .name{
                        font-weight: bold;
                        color: themed("textLinkColor");
                    }

                    .name:hover{
                        font-weight: bold;
                        color: #ab8bbd;
                    }
                    .date{
                        font-size: 11px;
                        
                    }
                }
            }
            span{
                color: themed("textColor");
            }
        }
        .content{
            margin: 20px 0px;
            color: themed("textColor");
            img{
                width: 100%;
                max-height: 500px;
                object-fit: cover;
                margin-top: 20px;
            }

        }

        
        .info{
            display: flex;
            justify-content: space-between;
            

            .items{
                display: flex;
                align-items: center;
                gap: 10px;
                cursor: pointer;
                color:themed("textColor");
            }
        }
    }

    @media screen and (max-width: 1166px) {
        .contain {
            width: 80%;
            margin-left: -10px;
        }
      }

      @media screen and (max-width: 740px) {
      
        .contain{
            width: 123%;
    margin-left: -174px;
    min-width: 280px;
        }
       
      }

      @media screen and (max-width: 600px) {
      
        .reactionName{
            display: none;
        }
       
      }

  
}}

.team-members {
  padding: 20px 0;
  
  .team-member {
    display: flex;
    align-items: center;
   // justify-content: space-between;
    margin-bottom: 10px;
    border: 1px solid #f7f5f5;
    padding: 10px;
    
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 10px;
    }
    
    .member-info {
      display: flex;
      flex-direction: column;
      
      .member-name {
        font-weight: 500;
      }
      
      .member-email {
        font-size: 0.9em;
        color: #555;
      }
    }
  }
}

.team-member {
  display: flex;
  align-items: center;
 // justify-content: space-between;
  margin-bottom: 10px;
  
  .info {
    margin-left: auto;
    display: flex;
    gap: 20px;
    
    .items {
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
      
      .reactionName {
        font-size: 14px;
        color: #555;
      }
    }
  }
}

  
.picture{
    width: 50%;
    display: flex;
}



.post{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.trend{
    margin-top: 107px;
    margin-bottom: 100px;
}

.profile-settings {
  padding: 20px;
  
  .settings-section {
    max-width: 600px;
    margin: 0 auto;
  }

  .profile-picture {
    text-align: center;
    margin-bottom: 30px;

    img {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      object-fit: cover;
      margin-bottom: 15px;
    }

    .change-photo {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      padding: 8px 16px;
      background-color: #f0f0f0;
      border: none;
      border-radius: 20px;
      cursor: pointer;
      margin: 0 auto;
      
      &:hover {
        background-color: #e0e0e0;
      }

      .material-symbols-rounded {
        font-size: 20px;
      }
    }
  }

  .form-group {
    margin-bottom: 20px;

    label {
      display: block;
      margin-bottom: 8px;
      font-weight: 500;
      color: #333;
    }

    input, 
    select, 
    textarea {
      width: 100%;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 8px;
      font-size: 14px;

      &:focus {
        outline: none;
        border-color: #007bff;
      }
    }

    textarea {
      min-height: 100px;
      resize: vertical;
    }
  }

  .form-actions {
    display: flex;
    gap: 15px;
    margin-top: 30px;

    button {
      padding: 10px 20px;
      border: none;
      border-radius: 6px;
      cursor: pointer;
      font-weight: 500;
      transition: background-color 0.2s;
    }

    .save-button {
      background-color: #007bff;
      color: white;

      &:hover {
        background-color: #0056b3;
      }
    }

    .cancel-button {
      background-color: #f0f0f0;
      color: #333;

      &:hover {
        background-color: #e0e0e0;
      }
    }
  }
}

// Add responsive styles
@media (max-width: 768px) {
  .profile-settings {
    padding: 15px;

    .settings-section {
      max-width: 100%;
    }

    .form-actions {
      flex-direction: column;
      
      button {
        width: 100%;
      }
    }
  }
}

.settings-content {
  padding: 20px;

  h3 {
    margin-bottom: 24px;
    color: #333;
    font-size: 1.5rem;
  }

  .notification-settings {
    max-width: 700px;
    margin: 0 auto;

    .notification-group {
      background: #fff;
      border-radius: 10px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
      padding: 15px;
    }

    .notification-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      border-bottom: 1px solid #eee;

      &:last-child {
        border-bottom: none;
      }

      .notification-info {
        flex: 1;
        padding-right: 20px;

        h4 {
          margin: 0 0 8px 0;
          color: #333;
          font-size: 1.1rem;
        }

        p {
          margin: 0;
          color: #666;
          font-size: 0.9rem;
          line-height: 1.4;
        }
      }
    }

    // Toggle Switch Styles
    .toggle-switch {
      position: relative;
      display: inline-block;
      width: 50px;
      height: 26px;

      input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + .slider {
          background-color: #007bff;
        }

        &:checked + .slider:before {
          transform: translateX(24px);
        }
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: .4s;
        border-radius: 34px;

        &:before {
          position: absolute;
          content: "";
          height: 20px;
          width: 20px;
          left: 3px;
          bottom: 3px;
          background-color: white;
          transition: .4s;
          border-radius: 50%;
        }
      }
    }

    .form-actions {
      display: flex;
      gap: 15px;
      margin-top: 30px;
      padding: 20px 0;

      button {
        padding: 12px 24px;
        border: none;
        border-radius: 6px;
        cursor: pointer;
        font-weight: 500;
        transition: all 0.2s ease;
      }

      .save-button {
        background-color: #007bff;
        color: white;

        &:hover {
          background-color: #0056b3;
        }
      }

      .cancel-button {
        background-color: #f0f0f0;
        color: #333;

        &:hover {
          background-color: #e0e0e0;
        }
      }
    }
  }
}

// Responsive styles
@media (max-width: 768px) {
  .settings-content {
    padding: 15px;

    .notification-settings {
      .notification-item {
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;

        .notification-info {
          padding-right: 0;
        }

        .toggle-switch {
          align-self: flex-start;
        }
      }

      .form-actions {
        flex-direction: column;
        
        button {
          width: 100%;
        }
      }
    }
  }
}

.security-settings {
  max-width: 700px;
  margin: 0 auto;
  padding: 20px;

  .security-section {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    padding: 24px;
    margin-bottom: 24px;

    h3 {
      margin: 0 0 20px 0;
      color: #333;
      font-size: 1.2rem;
    }

    // Password form styles
    .form-group {
      margin-bottom: 20px;

      label {
        display: block;
        margin-bottom: 8px;
        font-weight: 500;
        color: #333;
      }

      input {
        width: 100%;
        padding: 12px;
        border: 1px solid #ddd;
        border-radius: 6px;
        font-size: 14px;

        &:focus {
          outline: none;
          border-color: #007bff;
          box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.1);
        }
      }
    }

    .change-password-btn {
      background-color: #007bff;
      color: white;
      padding: 12px 24px;
      border: none;
      border-radius: 6px;
      cursor: pointer;
      font-weight: 500;
      width: 100%;
      transition: background-color 0.2s;

      &:hover {
        background-color: #0056b3;
      }
    }

    // Two-factor authentication styles
    .two-factor-toggle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;

      .toggle-info {
        flex: 1;

        p {
          margin: 0 0 5px 0;
          color: #333;
        }

        .security-note {
          color: #666;
          font-size: 0.9rem;
        }
      }

      .toggle-switch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 26px;

        input {
          opacity: 0;
          width: 0;
          height: 0;

          &:checked + .slider {
            background-color: #007bff;
          }

          &:checked + .slider:before {
            transform: translateX(24px);
          }
        }

        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #ccc;
          transition: .4s;
          border-radius: 34px;

          &:before {
            position: absolute;
            content: "";
            height: 20px;
            width: 20px;
            left: 3px;
            bottom: 3px;
            background-color: white;
            transition: .4s;
            border-radius: 50%;
          }
        }
      }
    }

    // Login history styles
    .login-history {
      .history-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0;
        border-bottom: 1px solid #eee;

        &:last-child {
          border-bottom: none;
        }

        .device-info {
          display: flex;
          align-items: center;
          gap: 15px;

          .material-symbols-rounded {
            font-size: 24px;
            color: #666;
          }

          div {
            p {
              margin: 0 0 4px 0;
              color: #333;
              font-weight: 500;
            }

            span {
              color: #666;
              font-size: 0.9rem;
            }
          }
        }

        .current-device {
          color: #28a745;
          font-size: 0.9rem;
          font-weight: 500;
        }

        .remove-device {
          padding: 6px 12px;
          background-color: #dc3545;
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-size: 0.9rem;
          transition: background-color 0.2s;

          &:hover {
            background-color: #c82333;
          }
        }
      }
    }
  }
}

// Responsive styles
@media (max-width: 768px) {
  .security-settings {
    padding: 15px;

    .security-section {
      padding: 20px;

      .two-factor-toggle {
        flex-direction: column;
        align-items: flex-start;
      }

      .history-item {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        .device-info {
          width: 100%;
        }

        .remove-device,
        .current-device {
          margin-top: 10px;
          align-self: flex-start;
        }
      }
    }
  }
}