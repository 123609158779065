@import "../../../style.scss";
.rightbar{
    @include themify($themes){
    flex: 3;
    position: sticky;
    top: 71px;
    height: 100vh;
    overflow: scroll;
    padding-top: 10px;
    
    &::-webkit-scrollbar{display: none;}

    @media screen and (max-width: 1166px) {
            flex: 4 !important;
        
      }

      @media screen and (max-width: 960px) {
        display: none;
  }


   
   

    .contain {
        padding: 20px;
        border:  1px solid themed("border");
        margin-right: 20px;
        border-radius: 10px;
        margin-top: 20px;
        background-color: themed("bg");
        .item{
            margin-bottom: 20px;
            .MemuTag{
                color: #aeaeae;
            }

            .user{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 20px 0px;


                .userInfo{
                    display: flex;
                    align-items: center;
                    gap:10px;

                    img{
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                .span {
                    color: themed("textColor");
                }
                }
            }

            .trend{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 20px 0px;
                cursor: pointer;
                color: themed("textColor");

                .trendingNumber{
                    font-size: 11px;
                    color: #aeaeae;
                }
            }
            .trend:hover{
                color: themed("textLinkColor");
            }
        }
    }

    .footer{
        font-size: 11px;
        margin-top: 20px;
        display: flex;
        margin-bottom: 220px;
        color: themed("textColor");
    }

    .followName{
        color: themed("textColor");
    }

    .hrColor{
      border-bottom: 1px solid themed("border");
      border-top: none;
      border-left: none;
      border-right: none;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .gallery {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;
      padding: 15px;
      background: white;
      border-radius: 10px;

      .galleryItem {
        position: relative;
        aspect-ratio: 1;
        overflow: hidden;
        border-radius: 8px;
        cursor: pointer;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.2);
          opacity: 0;
          transition: opacity 0.3s ease;
          z-index: 1;
        }

        &:hover {
          &::before {
            opacity: 1;
          }

          img {
            transform: scale(1.05);
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.3s ease;
        }
      }
    }

    // Responsive styles
    @media (max-width: 768px) {
      .gallery {
        grid-template-columns: repeat(2, 1fr);
        gap: 8px;
        padding: 10px;
      }
    }

    @media (max-width: 480px) {
      .gallery {
        grid-template-columns: repeat(2, 1fr);
        gap: 6px;
        padding: 8px;
      }
    }

    // Optional: Loading state
    .galleryItem.loading {
      background: #f0f0f0;
      animation: pulse 1.5s infinite;
    }

    @keyframes pulse {
      0% { opacity: 0.6; }
      50% { opacity: 1; }
      100% { opacity: 0.6; }
    }

    .view-all-link {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      padding: 12px;
      margin-top: 15px;
      background-color: #f8f9fa;
      border-radius: 10px;
      cursor: pointer;
      transition: all 0.2s ease;

      span {
        &:first-child {
          font-size: 14px;
          font-weight: 500;
          color: #2c3e50;
        }

        &.material-symbols-rounded {
          font-size: 20px;
          color: #2c3e50;
          transition: transform 0.2s ease;
        }
      }

      &:hover {
        background-color: #e9ecef;

        .material-symbols-rounded {
          transform: translateX(4px);
        }
      }

      // Optional: Active state
      &:active {
        transform: scale(0.98);
      }
    }

    // Dark mode support
    @media (prefers-color-scheme: dark) {
      .view-all-link {
        background-color: #2d2d2d;

        span {
          &:first-child {
            color: #fff;
          }

          &.material-symbols-rounded {
            color: #fff;
          }
        }

        &:hover {
          background-color: #333;
        }
      }
    }
}



}