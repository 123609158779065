.register{
    height: 100vh;
    background-color: #fafafa;
    display: flex;
    align-items: center;
    justify-content: center;
    .card{
        flex-direction: row-reverse;
        border-radius: 10px;
        width: 50%;
        display: flex;
        background-color: #fff;
        min-height: 500px;
        min-width: 800px;
        border:  1px solid #f2f2f2;
        .left{
            flex: 1;
            background: linear-gradient( rgba(63, 34, 89, 0.9),  rgba(5, 45, 58, 0.5)), url("https://images.unsplash.com/photo-1607013502213-a33bdd54851a?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8cGV4ZWxzfGVufDB8fDB8fHww");
            background-size: cover;
            padding: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
           // gap: 30px;
            color: #fff;
            line-height: 70px;
            border-radius: 0px 10px 10px 0px;
            h1{
                font-size: 80px;
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
            }
            p{line-height: 17px;}
            a {
               color: #fff; 
            }
            a:hover{color: #6f428b;
                text-decoration: none;
                }
            span{
                font-size: 14px;
            }
            button{
                margin-top: 20px;
                width: 50%;
                border: none;
                background-color: #fff;
                color: #333;
                cursor: pointer;
                padding: 0px;
            }
            button:hover{
                margin-top: 20px;
                width: 50%;
                border: none;
                background-color: #6f428b;
                color: #fff;
                cursor: pointer;
                padding: 0px;
            }
            
        }
        .right{
            flex: 1;
            padding: 50px;
            display: flex;
            flex-direction: column;
            gap: 50px;
            justify-content: center;
           
            h2{
                color: #333;
                padding-bottom: 30px;
            }

            img{
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 32px;
            }
            form{
                display: flex;
                flex-direction: column;
                gap: 20px;
                margin-top: -61px;
                input{
                    border: none;
                    //background-color: #f2f2f2;
                    //border: 1px solid #b7b7b7;
                    padding: 15px 15px;
                    border-radius:30px ;
                    border:  1px solid #f2f2f2;
                }
                button{
                    width: 50%;
                    padding: 15px;
                    border-radius:30px ;
                    border: none;
                    background-color: #70438d;
                    color: #fff;
                    cursor: pointer;
                    font-weight: bold;
                }
            }
        }
    }
}