@import "../../../style.scss";

.posts{
    @include themify($themes){
        display: flex;
        flex-direction: column;
        justify-content: center;
    .contain{
        padding: 20px;
        border:  1px solid themed("border");
        border-radius: 10px;
        background-color: themed("bg");
        width: 70%;
        margin: auto;
        .user{
            display: flex;
            align-items: center;
            justify-content: space-between;

          
            .userInfo{
                display: flex;
                gap: 20px;


                img{
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    object-fit: cover;
                }
                .details{
                    display: flex;
                    flex-direction: column;
                    color: themed("textColor");

                    .name{
                        font-weight: bold;
                        color: themed("textLinkColor");
                    }

                    .name:hover{
                        font-weight: bold;
                        color: #ab8bbd;
                    }
                    .date{
                        font-size: 11px;
                        
                    }
                }
            }
            span{
                color: themed("textColor");
            }
        }
        .content{
            margin: 20px 0px;
            color: themed("textColor");
            img{
                width: 100%;
                max-height: 500px;
                object-fit: cover;
                margin-top: 20px;
            }

        }

        
        .info{
            display: flex;
            justify-content: space-between;
            

            .items{
                display: flex;
                align-items: center;
                gap: 10px;
                cursor: pointer;
                color:themed("textColor");
            }
        }
    }

    @media screen and (max-width: 1166px) {
        .contain {
            width: 80%;
            margin-left: -10px;
        }
      }

      @media screen and (max-width: 740px) {
      
        .contain{
            width: 123%;
    margin-left: -174px;
    min-width: 280px;
        }
       
      }

      @media screen and (max-width: 600px) {
      
        .reactionName{
            display: none;
        }
       
      }

  
}}

.team-members {
  padding: 20px 0;
  
  .team-member {
    display: flex;
    align-items: center;
   // justify-content: space-between;
    margin-bottom: 10px;
    border: 1px solid #f7f5f5;
    padding: 10px;
    
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 10px;
    }
    
    .member-info {
      display: flex;
      flex-direction: column;
      
      .member-name {
        font-weight: 500;
      }
      
      .member-email {
        font-size: 0.9em;
        color: #555;
      }
    }
  }
}

.team-member {
  display: flex;
  align-items: center;
 // justify-content: space-between;
  margin-bottom: 10px;
  
  .info {
    margin-left: auto;
    display: flex;
    gap: 20px;
    
    .items {
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
      
      .reactionName {
        font-size: 14px;
        color: #555;
      }
    }
  }
}

  
.picture{
    width: 50%;
    display: flex;
}


.settings-tabs {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px 0;
  }

  .tab {
    padding: 15px;
    border-radius: 8px;
    transition: background-color 0.2s;
  }

  .tab:hover {
    background-color: #f5f5f5;
  }

  .tab a {
    display: flex;
    align-items: center;
    gap: 15px;
    color: inherit;
    text-decoration: none;
  }

  .tab-text {
    font-size: 16px;
  }

  .material-symbols-rounded {
    font-size: 24px;
  }

  .settings-content {
    margin-top: 15px;
    padding: 20px;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #f7f5f5;
  }