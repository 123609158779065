@import "../../../../style.scss";

.leftbarHr{
    @include themify($themes){
    flex: 2;
    position: fixed;
    top: 70px;
    height: 100vh;
    background-color:themed("bg") ;
    border-right: 1px solid themed("border");
    
   // height: 100vh;
   // overflow: scroll;
   // &::-webkit-scrollbar{display: none;}
    .contain{
        padding: 20px;
        width: 45px;
        padding-left: 10px;
        padding-right: 15px;
        .menu{
          display: flex;
          flex-direction: column;
          
         // gap: 20px;
            .items{
                display: flex;
                align-items: center;
                gap: 10px;
                font-weight: bold;
                cursor: pointer;
                padding: 10px 0px;
                color: themed("textColor");

                .Icon-Button{
                    display: flex;
                align-items: center;
                gap: 10px;
                }
            }

            .items:hover{
                background-color: themed("sidebarBgLink");
                display: flex;
                align-items: center;
               // gap: 10px;
               padding: 10px 0px;
                font-weight: bold;
                cursor: pointer;
                border-radius: 10px;

                .Icon-Button{
                    display: flex;
                align-items: center;
                gap: 10px;
                }
            }
        }
    }

    .rightLink{
        padding-left: 10px;
    }

.MemuTag{
    color: #aeaeae;
    padding-left: 10px;
}

.hrColor{
    border-bottom: 1px solid themed("border");
  border-top: none;
  border-left: none;
  border-right: none;
  margin-top: 20px;
  margin-bottom: 20px;
}

.linkName {
    display: none;
}



@media screen and (max-width: 740px) {
    .linkName {
        display: none;
    }
    .contain{
        width: 70px;
    }
   
    

  }

}
}