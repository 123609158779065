@import "../../../style.scss";

.comments{
    @include themify($themes){
        img{
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
        }

        .write{
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            margin: 20px 0px;
        }

        input{
            width: 100%;
            padding: 10px;
            border: 1px solid themed("border");
            background-color: transparent;
            color: themed("textColor");
            border-radius: 50px;
        }

        .CommentLink{
            color:themed("textLinkColor");
            cursor: pointer;

        }
        .comment{
            
            display: flex;
            justify-content: space-between;
            gap: 15px;

            .info{
                flex: 5;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                border-right: 1px solid themed("border");

                .name{
                    color:themed("textLinkColor");
                    cursor: pointer;
                }

                .name:hover{
                    color: #ab8bbd;
                    cursor: pointer;
                }
               
                p{
                    color: themed("textColor");
                }

                .reaction{
                    display: flex;
                    justify-content: space-between;
                    width: 65%;
                    font-size: 13px;
                }
            }

            .date{
                flex: 1;
                align-self:center;
                color: themed("textColorSoft");
                font-size: 11px;
            }
        }

        .hrColor{
            border-bottom: 1px solid themed("border");
          border-top: none;
          border-left: none;
          border-right: none;
          margin-top: 20px;
          margin-bottom: 20px;
        }


        @media screen and (max-width: 600px) {
      
            .reactionName{
                display: none;
            }
           
          }
    }
}