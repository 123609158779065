.login{
    height: 100vh;
    background-color: #fafafa;
    display: flex;
    align-items: center;
    justify-content: center;
    .card{
        border-radius: 10px;
        width: 24%;
        display: flex;
        background-color: #fff;
        min-height: 500px;
        min-width: 495px;
        border:  1px solid #f2f2f2;
        .left{
            flex: 1;
            background: linear-gradient( rgba(63, 34, 89, 0.9),  rgba(5, 45, 58, 0.5)), url("https://images.pexels.com/photos/1563356/pexels-photo-1563356.jpeg?cs=srgb&dl=pexels-craig-adderley-1563356.jpg&fm=jpg");
            background-size: cover;
            padding: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
           // gap: 30px;
            color: #fff;
            line-height: 70px;
            border-radius: 10px 0px 0px 10px;
            h1{
                font-size: 80px;
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
            }
            p{line-height: 17px;}
            span{
                font-size: 14px;
            }
            button{
                margin-top: 20px;
                width: 50%;
                padding: 15px;
                border-radius:30px ;
                border: none;
                background-color: #fff;
                color: #333;
                cursor: pointer;
            }
        }
        .right{
            flex: 1;
            padding: 50px;
            display: flex;
            flex-direction: column;
            gap: 50px;
            justify-content: center;
           
            h1{
                color: #333;
            }

            img{
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 32px;
            }
            form{
                display: flex;
                flex-direction: column;
                gap: 20px;
                margin-top: -61px;
                input{
                    border: none;
                    //background-color: #f2f2f2;
                    //border: 1px solid #b7b7b7;
                    padding: 15px 15px;
                    border-radius:30px ;
                    border:  1px solid #f2f2f2;
                }
                button{
                    width: 50%;
                    padding: 15px;
                    border-radius:30px ;
                    border: none;
                    background-color: #70438d;
                    color: #fff;
                    cursor: pointer;
                    font-weight: bold;
                    margin-left: auto;
                }
            }
        }
    }
}

.error-message {
  color: #ff4444;
  margin-bottom: 15px;
  text-align: center;
  font-size: 14px;
}

.forgot-password {
  margin-top: 15px;
  text-align: center;
  
  a {
    color: #666;
    font-size: 14px;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
}