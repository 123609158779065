@import "../../style.scss";

.posts{
    @include themify($themes){
        display: flex;
        flex-direction: column;
        justify-content: center;
    .contains{

        // User Section
        .Home-user {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px 20px;
            position: relative;
           // background: linear-gradient(to bottom, #f8f9fa, white);
            border-radius: 15px;

            .Home-userInf {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                gap: 25px;

                .Home-avatar {
                    width: 180px;
                    height: 180px;
                    border-radius: 50%;
                    object-fit: cover;
                    border: 5px solid white;
                    transition: transform 0.3s ease;

                    &:hover {
                        transform: scale(1.05);
                    }
                }

                .Home-details {
                    text-align: center;

                    .Home-name {
                        display: block;
                        font-size: 28px;
                        font-weight: 600;
                        color: #2c3e50;
                        margin-bottom: 8px;
                    }

                    .Home-title {
                        display: block;
                        font-size: 16px;
                        color: #666;
                    }
                }
            }

            .Home-actions {
                margin-top: 20px;
                width: 100%;
                display: flex;
                justify-content: center;

                .Home-editProfile {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    padding: 12px 24px;
                    background-color: #f8f9fa;
                    border: 1px solid #e9ecef;
                    border-radius: 12px;
                    font-size: 15px;
                    font-weight: 500;
                    color: #495057;
                    cursor: pointer;
                    transition: all 0.2s ease;

                    &:hover {
                        background-color: #e9ecef;
                        transform: translateY(-2px);
                    }

                    .material-symbols-rounded {
                        font-size: 20px;
                    }
                }
            }
        }

        // Stats Section
        .profileStats {
            display: flex;
            justify-content: center;
            gap: 50px;
            padding: 30px 0;
            margin: 30px 0;
            border-top: 1px solid #eee;
            border-bottom: 1px solid #eee;

            .stat {
                text-align: center;

                .count {
                    display: block;
                    font-size: 24px;
                    font-weight: 600;
                    color: #2c3e50;
                    margin-bottom: 5px;
                }

                .label {
                    font-size: 14px;
                    color: #666;
                    text-transform: uppercase;
                    letter-spacing: 0.5px;
                }
            }
        }

        // Bio Section
        .bio {
            text-align: center;
            padding: 0 20px;

            p {
                font-size: 16px;
                line-height: 1.6;
                color: #495057;
                margin-bottom: 25px;
            }

            .Home-location,
            .Home-website {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
                margin-bottom: 15px;
                color: #666;

                .material-symbols-rounded {
                    font-size: 20px;
                    color: #666;
                }

                span {
                    font-size: 15px;
                }
            }

            .Home-website {
                a {
                    color: #007bff;
                    text-decoration: none;
                    font-weight: 500;
                    
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    // Responsive Styles
    @media (max-width: 768px) {
        .contains {
            margin: 0;
            border-radius: 0;
            padding: 15px;

            .Home-user {
                padding: 30px 15px;

                .Home-userInf {
                    .Home-avatar {
                        width: 150px;
                        height: 150px;
                    }

                    .Home-details {
                        .Home-name {
                            font-size: 24px;
                        }

                        .Home-title {
                            font-size: 14px;
                        }
                    }
                }
            }

            .profileStats {
                gap: 30px;
                padding: 20px 0;

                .stat {
                    .count {
                        font-size: 20px;
                    }

                    .label {
                        font-size: 12px;
                    }
                }
            }

            .bio {
                padding: 0 15px;

                p {
                    font-size: 14px;
                }
            }
        }
    }

    // Dark Mode (optional)
    @media (prefers-color-scheme: dark) {
        .contains {
            background: #1a1a1a;
            
            .Home-user {
                background: linear-gradient(to bottom, #242424, #1a1a1a);

                .Home-userInf {
                    .Home-details {
                        .Home-name {
                            color: #fff;
                        }
                        .Home-title {
                            color: #aaa;
                        }
                    }
                }

                .Home-actions {
                    .Home-editProfile {
                        background-color: #2d2d2d;
                        border-color: #333;
                        color: #fff;

                        &:hover {
                            background-color: #333;
                        }
                    }
                }
            }

            .profileStats {
                border-color: #333;
                
                .stat {
                    .count {
                        color: #fff;
                    }
                    .label {
                        color: #aaa;
                    }
                }
            }

            .bio {
                p {
                    color: #ddd;
                }
                .Home-location,
                .Home-website {
                    color: #aaa;
                    .material-symbols-rounded {
                        color: #aaa;
                    }
                }
            }
        }
    }
}
}

.profile {
    padding-top: 100px;
}